:root {
	--color-primary: #335AA6;
	--color-primary-light: #89ABD9;
	--color-primary-dark: #18358C;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #ccc;
	--color-grey-dark-1: #404040;
	--color-grey-dark-2: #777;
	--color-grey-dark-3: #999;
	--color-red: #A52631;
	--color-green: #207245;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0 ;
	padding: 0 ;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%; 
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	} 
}
body {
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-color: var(--color-grey-light-3);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	font-family: 'Roboto', sans-serif;
}

/* ANIMATION */
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.app {
	min-height: 100vh;
	padding-top: 6rem;
}
header {
	display: flex;
	background-color: #fff;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	box-shadow: var(--shadow-light);
}
.header__container {
	width: 100%;
	max-width: 120rem;
	display: flex;
	margin: 0 auto;
}
.header__container picture {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	margin-right: auto;
}
.header__container picture img {
	height: 4rem;
}
.header__container ul {
	display: flex;
}
.header__container ul a {
	display: flex;
	align-items: center;
	margin-right: 1rem;
	padding: 0 1rem;
	font-size: 1.4rem;
	text-decoration: none;
	color: var(--color-primary);
	text-transform: uppercase;
	font-weight: 500;
}
footer {
	margin-top: auto;
	background-color: #fff;
	width: 100%;
}
.footer-top {
	display: flex;
	padding: 4.5rem 0;
	border-bottom: var(--line);
}
.footer-top__container {
	display: flex;
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.footer-top__container ul {
	flex: 0 0 20%;
	display: flex;
	flex-direction: column;
}
.footer-top__container ul a {
	margin: 1rem;
	cursor: pointer;
	padding: .5rem;
	font-size: 1.4rem;
	color: var(--color-primary);
	text-decoration: none;
}
.footer-bottom {
	padding: 2rem;
}
.footer-bottom__container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	font-size: 1.2rem;
	color: var(--color-primary);
}
.loader {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-primary-dark);
}
.loader i {
	-webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
	animation:spin 4s linear infinite;
	font-size: 4.5rem;
	color: #fff;
}
.input-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
}
.input-container input,
.input-container textarea {
	padding: 1rem;
	margin-top: 1rem;
	background-color: rgba(255, 255, 255, .6);
	border: none;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
	outline: none;
	resize: none;
}
.input-container button {
	border: 1px solid var(--color-primary);
	padding: 1rem;
	color: var(--color-primary);
	font-size: 1.4rem;
	cursor: pointer;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.input-container button i {
	margin-left: 1rem;
}
.input-container button:hover {
	background-color: var(--color-primary);
	color: #fff;
}
.input-container i.loading {
	-webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
	animation:spin 4s linear infinite;
	font-size: 2.5rem;
	color: var(--color-primary);
	margin: 0 auto;
}
table {
	width: 100%;
	border-collapse: collapse;
	font-size: 1.2rem;
}
table th {
	background-color: var(--color-primary);;
	padding: 1rem;
	text-transform: uppercase;
	color: #fff;
	font-weight: 600;
}
table td {
	color: var(--color-grey-dark-1);
	padding: 1rem;
}
table tr:nth-of-type(even) td {
	background-color: rgba(91, 40, 125, .4);
}
table tr.red td {
	background-color: rgba(165, 38, 49, .3);
}
table td a {
	color: var(--color-primary);
	text-decoration: none;
}
table td input,
table td select {
	width: 100%;
	padding: .5rem;
	border: none;
	box-shadow: .5px .5px 1px rgba(0, 0, 0, 0.1);
	background-color: rgba(255,255,255,.3);
	outline: none;
}
table td.center {
	text-align: center;
}
table td.center button {
	margin: 0 auto;
}
table td button {
	border: none;
	background-color: transparent;
	display: flex;
	cursor: pointer;
	color: var(--color-primary-dark);
	outline: none;
}
table th button {
	padding: 1rem;
	cursor: pointer;
	outline: none;
	background-color: var(--color-primary-light);
	border: none;
	color: #fff;
}

/* HOME */
.home {
	min-height: 100vh;
}
.home__cover {
	height: 80vh;
	background-image: url('/images/bg1.png');
	background-size: cover;
	background-position: center;
	background-repeat: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background-attachment: fixed;
}
.home__cover h1 {
	font-size: 4.5rem;
	text-align: center;
	color: #fff;
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
.home__first {
	padding: 9rem 0;
	display: flex;
	height: 60vh;
}
.home__first-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	display: flex;
}
.home__first-container .list {
	flex: 0 0 50%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}
.home__first-container .list ul {
	display: flex;
	flex-direction: column;
	list-style: none;
}
.home__first-container .list > span {
	font-size: 1.4rem;
	color: var(--color-grey-dark-3);
	letter-spacing: 2px;
	text-transform: uppercase;
}
.home__first-container .list > h3 {
	font-size: 2rem;
	letter-spacing: 3px;
	text-transform: uppercase;
	color: var(--color-grey-dark-1);
	margin-top: 1rem;
	margin-bottom: 2rem;
}
.home__first-container .list ul li {
	display: flex;
	cursor: pointer;
	margin-bottom: 2rem;
}
.home__first-container .list ul li picture {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 2;
}
.home__first-container .list ul li picture img {
	width: 100%;
}
.home__first-container .list ul li .container {
	flex: 6;
	background-color: #fff;
	padding: 2rem;
}
.home__first-container .list ul li .container h4 {
	font-size: 1.6rem;
	color: var(--color-grey-dark-1);
	font-weight: 500;
	padding: .5rem;
	border-bottom: 1px solid var(--color-grey-dark-1);
}
.home__first-container .list ul li .container p {
	margin-top: 1rem;
	font-size: 1.4rem;
}
.home__first-container .list ul li .container p span {
	color: var(--color-grey-dark-3);
}
.home__first-container .list ul li .container p b {
	color: var(--color-grey-dark-1);
}
.home__first-container .preview {
	flex: 1;
	padding: 2rem;
	display: flex;
}
.home__first-container .preview__container {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
}
.home__rooms {
	padding: 9rem 0;
	background-color: #fff;
	display: flex;
}
.home__rooms-container {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
}
.home__rooms-container h2 {
	font-size: 2.5rem;
	color: var(--color-primary);
	padding: 1rem;
	border-bottom: 2px solid var(--color-primary);
}
.home__rooms-container ul {
	display: flex;
	list-style: none;
	padding: 2.5rem;
}
.home__rooms-container ul li {
	display: flex;
	position: relative;
	flex: 1 0 22.5%;
	filter: grayscale(1);
	transition: all .5s;
}
.home__rooms-container ul li:hover {
	filter: grayscale(0);
}
.home__rooms-container ul li:not(:last-of-type) {
	margin-right: 2.5rem;
}
.home__rooms-container ul li picture {
	display: flex;
	position: relative;
}
.home__rooms-container ul li picture img {
	width: 100%;
}
.home__separator {
	padding: 12rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url('/images/bg2.png');
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
}
.home__separator h3 {
	text-align: center;
	font-size: 2.5rem;
	color: #fff;
	text-transform: uppercase;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}
.home__separator-container {
	display: flex;
	flex-direction: column;
}
.home__separator-container a {
	margin: 1.5rem auto;
	padding: 1rem 1.5rem;
	color: var(--color-primary);
	border: 2px solid var(--color-primary);
	font-size: 1.4rem;
	text-decoration: none;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 1px;
}
.home__separator-container a:hover {
	background-color: var(--color-primary);
	color: #fff;
}

/* ABOUT */
.about {
	display: flex;
	min-height: 100vh;
}
.about__container {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
	padding: 2.5rem;
	background-color: #fff;
}
.about__container h1 {
	font-size: 2.5rem;
	text-align: center;
	color: var(--color-grey-dark-1);
	padding: 1rem;
	border-bottom: 2px solid var(--color-grey-dark-1);
	text-transform: uppercase;
	letter-spacing: 2px;
}
.about__container p {
	padding: 1rem;
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	margin-top: 2rem;
	text-align: justify;
}
.about__container h2 {
	font-size: 2rem;
	color: var(--color-grey-dark-1);
	text-transform: uppercase;
	letter-spacing: 2px;
	padding: 1rem;
	margin-top: 2.5rem;
}
.about__container ul {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 2.5rem 0;
}
.about__container ul li {
	display: flex;
	flex: 1 0 30%;
	padding: 1rem;
	align-items: center;
	font-size: 1.4rem;
	color: var(--color-grey-dark-1);
	text-transform: uppercase;
}
.about__container ul li i {
	color: #fff;
	background-color: var(--color-green);
	margin-right: 1rem;
	width: 3rem;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}

/* CONTACT */
.contact {
	min-height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2.5rem;
}
.contact__container {
	width: 100%;
	max-width: 100rem;
	padding: 2.5rem;
	background-color: rgba(255, 255, 255, .6);
}
.contact__container h1 {
	font-size: 2.5rem;
	color: var(--color-primary);
	text-transform: uppercase;
	border-bottom: 2px solid var(--color-primary);
	padding: 1rem;
}
.contact__container .content {
	display: flex;
	padding: 2rem;
}
.contact__container .content form {
	flex: 1;
	margin-right: 2rem;
}
.contact__container .content section {
	flex: 1;
}
.contact__container .content section ul {
	margin-bottom: 2.5rem;
	list-style: none;
}
.contact__container .content section ul li {
	display: flex;
	align-items: center;
	padding: 1.5rem 1rem;
	color: var(--color-primary);
	font-size: 1.4rem;
}
.contact__container .content section ul li i {
	margin-right: 1rem;
}
.contact__container .content section ul li a {
	color: var(--color-primary);
	text-decoration: none;
}